import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Gif from "./gif";

const Gifs = ({
  category,
  password,
  gifs,
  setRefresh,
}: {
  category: string;
  password: string;
  gifs: string[];
  setRefresh: Function;
}) => {
  useEffect(() => {
    setRefresh(true);
  }, [category, setRefresh]);

  return (
    <Container fluid>
      <div className="gallery-image">
        {gifs.map((gif, index) => {
          return (
            <Gif
              key={category + "-" + index}
              category={category}
              password={password}
              gif={gif}
              index={index}
              setRefresh={setRefresh}
            ></Gif>
          );
        })}
      </div>
    </Container>
  );
};

export default Gifs;
