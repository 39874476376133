import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import {
  addCategory,
  addGifIntoCategory,
  removeCategory,
} from "../../api/callApi";

const Menu = ({
  gifs,
  password,
  category,
  setPassword,
  categories,
  setCategory,
  setRefresh,
}: {
  gifs: string[];
  password: string;
  category: string;
  setPassword: Function;
  categories: string[];
  setCategory: Function;
  setRefresh: Function;
}) => {
  const [newCategory, setNewCategory] = useState("");
  const [toRemoveCategory, setToRemoveCategory] = useState(category);
  const [gif, setGif] = useState("");
  const [callApiNewCategory, setCallApiNewCategory] = useState(false);
  const [callApiRemoveCategory, setCallApiRemoveCategory] = useState(false);
  const [callApiGif, setCallApiGif] = useState(false);

  useEffect(() => {
    if (callApiNewCategory) {
      addCategory(password, newCategory, setRefresh);
      setNewCategory("");
      setCallApiNewCategory(false);
    }
    if (callApiRemoveCategory) {
      removeCategory(password, toRemoveCategory, setRefresh);
      setCallApiRemoveCategory(false);
    }
    if (callApiGif) {
      addGifIntoCategory(password, category, gif, setRefresh);
      setGif("");
      setCallApiGif(false);
    }
    setToRemoveCategory(category);
  }, [
    callApiNewCategory,
    callApiRemoveCategory,
    callApiGif,
    newCategory,
    toRemoveCategory,
    category,
    gif,
    password,
    setRefresh,
  ]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="custom-navbar"
    >
      <Container fluid>
        <Navbar.Brand>
          Gifs Manager / {category} ({gifs.length})
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto my-2 my-lg-0">
            <NavDropdown
              title="Categories"
              id="basic-nav-dropdown"
              menuVariant="dark"
              className="col-md-6"
            >
              {categories.map((category, index) => {
                return (
                  <NavDropdown.Item
                    key={index}
                    onClick={() => {
                      setCategory(category);
                    }}
                  >
                    {category}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Nav>
          <div className="d-flex custom-flex">
            <InputGroup className="p-1">
              <Form.Select
                onChange={(e) => {
                  setToRemoveCategory(e.target.value);
                  setCategory(e.target.value);
                }}
              >
                {categories.map((cat, index) => {
                  if (category === cat) {
                    return (
                      <option key={index} value={cat}>
                        {cat}
                      </option>
                    );
                  }
                  return (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  );
                }, category)}
              </Form.Select>
              <Button
                variant="outline-danger"
                onClick={(e) => {
                  setCallApiRemoveCategory(true);
                }}
              >
                🗑️
              </Button>
            </InputGroup>
            <InputGroup className="p-1">
              <FormControl
                type="text"
                placeholder="New Category"
                onChange={(e) => setNewCategory(e.target.value)}
                value={newCategory}
              />
              <Button
                variant="outline-success"
                onClick={(e) => {
                  setCallApiNewCategory(true);
                }}
              >
                ➕
              </Button>
            </InputGroup>
            <InputGroup className="p-1">
              <FormControl
                type="text"
                placeholder="New Gif"
                onChange={(e) => setGif(e.target.value)}
                value={gif}
              />
              <Button
                variant="outline-success"
                onClick={(e) => {
                  setCallApiGif(true);
                }}
              >
                ➕
              </Button>
            </InputGroup>
            <InputGroup className="p-1">
              <InputGroup.Text>🔐</InputGroup.Text>
              <FormControl
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Usage Password"
              />
            </InputGroup>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
