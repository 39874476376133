import Axios from "axios";
import config from "../config.json";

/**
 * Generate the url of the api
 * @param url Endpoint has added
 * @returns The final url
 */
const buildUrl = (url: string) => {
  return `${config.API_URL}${url}`;
};

/**
 * Refreshes the data
 * @param category The current category
 * @param setCategories Mofidier of categories
 * @param setCategory  Modifier of category
 * @param setGifs Modifier of gifs
 */
export const refreshData = async (
  category: string,
  setCategories: Function,
  setCategory: Function,
  setGifs: Function
) => {
  const cat = await getCategories(setCategories, category, setCategory);
  await getGifs(cat, setGifs);
};

/**
 * Get the list of categories
 * @param setCategories Modifier of the list of categories
 * @param category The current category
 * @param setCategory Modifier of category
 * @returns The new current category
 */
export const getCategories = async (
  setCategories: Function,
  category: string,
  setCategory: Function
): Promise<string> => {
  try {
    const response = await Axios.get(buildUrl("/categories"));
    if (response.status === 200) {
      setCategories(response.data);
      if (response.data.length > 0 && category === "") {
        setCategory(response.data[0]);
        return response.data[0];
      }
    }
  } catch (e) {
    console.error(e);
    setCategories([]);
  }
  return category;
};

/**
 * Get the gifs of a category
 * @param category The current category
 * @param setGifs Modifier of gifs
 */
export const getGifs = async (
  category: string,
  setGifs: Function
): Promise<void> => {
  try {
    const response = await Axios.get(buildUrl(`/categories/${category}`));
    if (response.status === 200) {
      setGifs(response.data);
    }
  } catch (e) {
    console.error(e);
    setGifs([]);
  }
};

/**
 * Adds a category to the database
 * @param usagePassword Password restricting use
 * @param category The current category
 * @param setCallApi Modifier of the api call
 */
export const addCategory = async (
  usagePassword: string,
  category: string,
  setCallApi: Function
) => {
  try {
    await Axios.post(buildUrl(`/categories/${category}`), {
      usagePassword: usagePassword,
    });
  } catch (e) {}
  setCallApi(true);
};

/**
 * Adds a gif to a category
 * @param usagePassword Password restricting use
 * @param category The current category
 * @param gif Link of the gif
 * @param setCallApi Modifier of the api call
 */
export const addGifIntoCategory = async (
  usagePassword: string,
  category: string,
  gif: string,
  setCallApi: Function
) => {
  try {
    await Axios.post(buildUrl(`/categories/${category}/add`), {
      link: gif,
      usagePassword: usagePassword,
    });
  } catch (e) {}
  setCallApi(true);
};

/**
 * Removes a category from the database
 * @param usagePassword Password restricting use
 * @param category The current category
 * @param setCallApi Modifier of the api call
 */
export const removeCategory = async (
  usagePassword: string,
  category: string,
  setCallApi: Function
) => {
  try {
    await Axios.delete(buildUrl(`/categories/${category}`), {
      data: { usagePassword: usagePassword },
    });
  } catch (e) {}
  setCallApi(true);
};

/**
 * Remove a gif from a category
 * @param usagePassword Password restricting use
 * @param category The current category
 * @param gif Link of the gif
 * @param setCallApi Modifier of the api call
 */
export const removeGif = async (
  usagePassword: string,
  category: string,
  gif: string,
  setCallApi: Function
) => {
  try {
    await Axios.delete(buildUrl(`/categories/${category}/remove`), {
      data: { link: gif, usagePassword: usagePassword },
    });
  } catch (e) {}
  setCallApi(true);
};
