import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { refreshData } from "../../api/callApi";
import Gifs from "../gifs";
import Menu from "../menu";

function App() {
  const [password, setPassword] = useState("");
  const [categories, setCategories] = useState([] as string[]);
  const [category, setCategory] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [gifs, setGifs] = useState([]);

  useEffect(() => {
    if (refresh || !categories.includes(category)) {
      refreshData(category, setCategories, setCategory, setGifs);
      setRefresh(false);
    }
  }, [refresh, category, categories]);

  return (
    <Container fluid>
      <Row>
        <Menu
          gifs={gifs}
          password={password}
          category={category}
          categories={categories}
          setCategory={setCategory}
          setPassword={setPassword}
          setRefresh={setRefresh}
        ></Menu>
      </Row>
      <Row>
        <Gifs
          gifs={gifs}
          category={category}
          password={password}
          setRefresh={setRefresh}
        ></Gifs>
      </Row>
    </Container>
  );
}

export default App;
