import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { removeGif } from "../../api/callApi";

const Gif = ({
  category,
  password,
  gif,
  index,
  setRefresh,
}: {
  category: string;
  password: string;
  gif: string;
  index: number;
  setRefresh: Function;
}) => {
  useEffect(() => {
    setRefresh(true);
  }, [category, setRefresh]);

  return (
    <div className="img-box gif-loading">
      <img
        loading="lazy"
        src={gif}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.src = "./error.gif";
          const parent = currentTarget.parentElement;
          if (parent) {
            parent.className = "img-box gif-error";
          }
        }}
        onLoad={({ currentTarget }) => {
          const parent = currentTarget.parentElement;
          if (parent && parent.className === "img-box gif-loading") {
            parent.className = "img-box gif-success";
          }
        }}
      />
      <div className="transparent-box">
        <span className="gif-id">#{index + 1}</span>
        <div className="caption">
          <p>Category: {category}</p>
          <p className="opacity-low">
            Source:{" "}
            <a href={gif} target={"_blank"} rel="noreferrer">
              {gif}
            </a>
          </p>
          <Button
            variant="outline-danger"
            onClick={() => {
              removeGif(password, category, gif, setRefresh);
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Gif;
